import React from 'react';
import { SketchPicker } from '@hello-pangea/color-picker';
import Button from '@mui/material/Button';
import './App.css';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import packageJson from '../package.json';
import SwitchMode from './darkSwitch';



class App extends React.Component {
  private red = 0
  private green = 0
  private blue = 0
  private strip = 0
  public lightMode: boolean;



  constructor(props: any) {
    super(props);
    this.lightMode = true;
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.lightMode = false
    }
  }

  private handleColorChange = ({ rgb }: any) => {
    console.log(rgb);
    this.red = rgb.r;
    this.green = rgb.g;
    this.blue = rgb.b;
    console.log(this.red);
    console.log(this.green);
    console.log(this.blue);

  }



  private handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === "arriba") {
      this.strip = 2
    }
    if (value === "abajo") {
      this.strip = 1
    }
    if (value === "todas") {
      this.strip = 0
    }
    console.log(value);
    console.log(this.strip);
  };


  private cliqueo = () => {
    fetch(`http://192.168.1.140:3000/all/${this.strip}/${this.red}/${this.green}/${this.blue}`, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json"
      }
    });
  };

  private twinkle = () => {
    fetch(`http://192.168.1.140:3000/twinkle/custom/${this.strip}/140`, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        palette: [
          { red: 128, green: 64, blue: 8 },
          { red: 16, green: 16, blue: 128 },
          { red: 128, green: 16, blue: 128 }
        ]

      })

    });
  };

  private apagar = () => {
    fetch(`http://192.168.1.140:3000/all/${this.strip}/0/0/0`, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json"
      }
    });
  };

  private version(): string {
    console.log(packageJson.version);
    return packageJson.version;
  }



  private tema = () => {

    const todo = document.querySelector('html') as HTMLElement;

    if (this.lightMode) {
      todo?.style.setProperty('--bg', '#3b4252');
      todo?.style.setProperty('--text', '#eee');
      todo?.style.setProperty('--sk', '#1c212b');

      this.lightMode = false

    } else {
      todo?.style.setProperty('--bg', '#fffacd');
      todo?.style.setProperty('--text', '#333');
      todo?.style.setProperty('--sk', '#faf2a7');

      this.lightMode = true;
    }
    
  }

  public render(): JSX.Element {
    return (
      <div className='App'>
        <div style={{ display: "flex", justifyContent: 'right', alignItems: "flex-end", marginTop: "20px" }}>

          <SwitchMode onClick={this.tema} defaultChecked={!this.lightMode} />
        </div>
        <h1 className='Titulo' style={{
          color: '--text',
          textAlign: "center"
        }}> Seleccion de color LED
        </h1>

        <Container maxWidth="sm" sx={{ display: 'flex', alignContent: 'space-around', justifyContent: "center" }}>
          <div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <SketchPicker disableAlpha={true} onChangeComplete={this.handleColorChange} />
            </div>
            <div style={{ marginTop: "20px" }}>




              <FormControl sx={{ marginTop: "20px", textAlign: "center" }}>
                <FormLabel id="demo-controlled-radio-buttons-group" sx={{ color: "var(--text)" }}> Seleccion de tira de luz</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "20px" }}
                  defaultValue={"todas"}
                  onChange={this.handleChange}>
                  <FormControlLabel value="todas" control={<Radio />} label="Todas" labelPlacement='bottom' />
                  <FormControlLabel value="arriba" control={<Radio />} label="Árbol" labelPlacement='bottom' />
                  <FormControlLabel value="abajo" control={<Radio />} label="Abajo" labelPlacement='bottom' />
                </RadioGroup>

                <Stack direction="row" spacing={2}>
                  <Button onClick={this.apagar} variant="contained">Apagar</Button>
                  <Button onClick={this.cliqueo} variant="contained"> Activame </Button>
                  <Button onClick={this.twinkle} variant="contained"> Mi Twinkle </Button>


                </Stack>
              </FormControl>
            </div>
          </div>
        </Container>
        <footer style={{ marginTop: "30px", marginLeft: "20px", textAlign: "center", opacity: "0.5" }} > Version N° {this.version()}</footer>
      </div>
    );
  }
}

export default App;
